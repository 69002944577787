import { FC, useState } from 'react'
import { Box, Button, Card, CardActions, CardContent, Grid, Paper, TextField, Typography } from '@mui/material'
import { brandValidationSchema } from '../core/validations'
import { Add, CancelOutlined, Loyalty, PhotoLibraryOutlined } from '@mui/icons-material'
import { useFormik } from 'formik'
import { useNavigate, useLocation } from 'react-router-dom'
import { BrandCreation } from '../core/api/types'
import { SaveBrandAction } from '../core/actions/brandActionTypes'
import { StorageGalleryContainer } from '../components'
import '../styles/brand-creation.scss'
import { Roles } from '../constants/roles'
import { RoleAccessProvider } from '../providers'

interface Props {
  createBrand: (brand: BrandCreation) => SaveBrandAction
}

const BrandCreationPageComponent: FC<Props> = ({ createBrand }) => {
  const [selectedLogo, setSelectedLogo] = useState<string>('')
  const [selectedImage, setSelectedImage] = useState<string>('')
  const navigate = useNavigate()
  const location = useLocation()
  
  const navigateToBrandsPage  = () => {
    navigate('/brands', { state: { from: location.pathname } })
  }

  const { values, errors, touched, handleSubmit, handleChange, setFieldValue } = useFormik({
    initialValues: {
      id: '',
      name: '',
      logo: '',
      image: '',
    },
    validationSchema: brandValidationSchema,
    onSubmit: (brand: BrandCreation) => {
      console.log(brand)
      createBrand(brand)
      navigateToBrandsPage()
    },
  })
  

  return (
    <RoleAccessProvider required={[Roles.MANAGE_BRANDS]}>
    <div>
      <Grid spacing={4} container>
        <Grid item xs={6}>
          <Card sx={{ flex: 1 }}>
            <form onSubmit={handleSubmit}>
              <CardContent>
                <Box mb={1} sx={{ display: 'flex', alignItems: 'center' }}>
                  <Loyalty />
                  <Typography ml={2} variant={'h5'}>
                    <b>Create a new brand</b>
                  </Typography>
                </Box>
                <Grid spacing={2} container>
                  <Grid item xs={12}>
                    <TextField
                      id='name'
                      label='Name of the brand'
                      margin='normal'
                      type='text'
                      inputProps={{maxLength: 30}}
                      fullWidth
                      variant='filled'
                      value={values.name}
                      error={touched.name && Boolean(errors.name)}
                      onChange={handleChange}
                      helperText={touched.name && errors.name}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Paper variant={'outlined'}>
                      <Box className={'brand-creation-upload-area'}>
                        {selectedLogo === '' ? (
                          <PhotoLibraryOutlined className={'centered-icon'} />
                        ) : (
                          <img
                            className={'image-preview centered-icon'}
                            src={`${process.env.REACT_APP_HOST_BASE_URL}/api/storage/image?id=${selectedLogo}`}
                            alt={selectedLogo}
                            loading='lazy'
                          />
                        )}

                        <Typography className={'centered-title'} variant={'overline'}>
                          {selectedLogo === '' ? 'Select a logo from gallery' : selectedLogo}
                        </Typography>
                      </Box>
                    </Paper>
                  </Grid>

                  <Grid item xs={12}>
                    <Paper variant={'outlined'}>
                      <Box className={'brand-creation-upload-area'}>
                        {selectedImage === '' ? (
                            <PhotoLibraryOutlined className={'centered-icon'} />
                        ) : (
                            <img
                                className={'image-preview centered-icon'}
                                src={`${process.env.REACT_APP_HOST_BASE_URL}/api/storage/image?id=${selectedImage}`}
                                alt={selectedImage}
                                loading='lazy'
                            />
                        )}

                        <Typography className={'centered-title'} variant={'overline'}>
                          {selectedImage === '' ? 'Select a image from gallery' : selectedImage}
                        </Typography>
                      </Box>
                    </Paper>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions sx={{ backgroundColor: '#f1f1f1' }}>
                <Button type={'submit'} sx={{ margin: 1 }} startIcon={<Add />} variant={'contained'} color='primary'>
                  Create
                </Button>
                <Button
                  onClick={navigateToBrandsPage}
                  sx={{ margin: 1 }}
                  startIcon={<CancelOutlined />}
                  variant={'contained'}
                  color='primary'
                >
                  Cancel
                </Button>
              </CardActions>
            </form>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <StorageGalleryContainer
            onImageClicked={(file) => {
              setFieldValue('logo', file.identifier)
              setSelectedLogo(file.identifier)
            }}
          />
          <StorageGalleryContainer
              onImageClicked={(file) => {
                setFieldValue('image', file.identifier)
                setSelectedImage(file.identifier)
              }}
          />
        </Grid>
      </Grid>
    </div>
    </RoleAccessProvider>
  )
}

export default BrandCreationPageComponent
