import { FC, useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { createPharamacyValidationSchema } from '../core/validations'
import { Add, CancelOutlined } from '@mui/icons-material'
import { useFormik } from 'formik'
import { useNavigate, useLocation } from 'react-router-dom'
import { IdentityCreationData, PharmacyCreation, PharmacyFilter, Region } from '../core/api/types'
import { SavePharmacyAction } from '../core/actions/pharmacyActionTypes'
import { nets } from '../core/validations/consts-and-functions'
import { GetProgramNamesAction } from '../core/actions/programActionTypes'

interface Props {
  createPharmacy: (pharmacy: PharmacyCreation, filter?: PharmacyFilter) => SavePharmacyAction
  getProgramNames: () => GetProgramNamesAction
  regions: Region[]
  programNames: string[]
}

const PharmacyCreationPageComponent: FC<Props> = ({ createPharmacy, getProgramNames, programNames, regions }) => {
  const navigate = useNavigate()
  const location = useLocation() 
  const [programs, setPrograms] = useState<string[]>([])

  const goBackToPharmciesPage = () => {
    navigate('/pharmacies', { state: { from: location.pathname, filter: location.state?.filter }})
  }
  
  const { values, errors, touched, handleSubmit, handleChange } = useFormik({
    initialValues: {
      nameChinese: '',
      pharmacyNet: '',
      addressChinese: '',
      region: '',
      phoneNumber: '',
      email: '',
      isPasswordOneTime: false,
      isActive: true,
      dateRegistered: new Date(),
      group: 'Pharmacy',
      username: '',
      longitude: null,
      latitude: null,
    },
    validationSchema: createPharamacyValidationSchema,
    onSubmit: (pharmacy: any) => {
      const identityCreation: IdentityCreationData = {
        username: pharmacy.username,
        password: 'default',
        isPasswordOneTime: false,
        isActive: true,
        group: 'Pharmacy',
      }

      const pharmacyCreation: PharmacyCreation = {
        nameChinese: pharmacy.nameChinese,
        pharmacyNet: pharmacy.pharmacyNet,
        addressChinese: pharmacy.addressChinese,
        region: pharmacy.region,
        phoneNumber: pharmacy.phoneNumber,
        email: pharmacy.email,
        programs,
        identity: identityCreation,
        latitude: pharmacy.latitude,
        longitude: pharmacy.longitude,
      }

      createPharmacy(pharmacyCreation, location.state?.filter)
      goBackToPharmciesPage()
    },
  })

  useEffect(() => {
    getProgramNames()
  }, [])

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <Card sx={{ flex: 1 }}>
            <form onSubmit={handleSubmit}>
              <CardContent>
                <Grid padding={1} item xs={12}>
                  <TextField
                    id='username'
                    label='Pharmacy ID (Login ID)'
                    type='text'
                    fullWidth
                    variant='filled'
                    value={values.username}
                    error={touched.username && Boolean(errors.username)}
                    onChange={handleChange}
                    helperText={touched.username && errors.username}
                  />
                </Grid>
                <Grid container>
                  <Grid padding={1} item xs={6}>
                    <TextField
                      id='nameChinese'
                      label='Pharmacy Name'
                      type='text'
                      fullWidth
                      variant='filled'
                      value={values.nameChinese}
                      error={touched.nameChinese && Boolean(errors.nameChinese)}
                      onChange={handleChange}
                      helperText={touched.nameChinese && errors.nameChinese}
                    />
                  </Grid>
                  <Grid padding={1} item xs={6}>
                    <TextField
                      id='addressChinese'
                      label='Pharmacy Address'
                      type='text'
                      fullWidth
                      variant='filled'
                      value={values.addressChinese}
                      error={touched.addressChinese && Boolean(errors.addressChinese)}
                      onChange={handleChange}
                      helperText={touched.addressChinese && errors.addressChinese}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid padding={1} item xs={6}>
                    <TextField
                      id='email'
                      label='Pharmacy E-Mail address'
                      type='text'
                      fullWidth
                      variant='filled'
                      value={values.email}
                      error={touched.email && Boolean(errors.email)}
                      onChange={handleChange}
                      helperText={touched.email && errors.email}
                    />
                  </Grid>
                  <Grid padding={1} item xs={6}>
                    <TextField
                      id='phoneNumber'
                      label='Pharmacy Phone Number'
                      type='text'
                      fullWidth
                      variant='filled'
                      value={values.phoneNumber}
                      error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                      onChange={handleChange}
                      helperText={touched.phoneNumber && errors.phoneNumber}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid padding={1} item xs={6}>
                    <TextField
                        id='longitude'
                        label='Pharmacy longitude'
                        type='number'
                        fullWidth
                        variant='filled'
                        value={values.longitude}
                        error={touched.longitude && Boolean(errors.longitude)}
                        onChange={handleChange}
                        helperText={touched.longitude && errors.longitude}
                    />
                  </Grid>
                  <Grid padding={1} item xs={6}>
                    <TextField
                        id='latitude'
                        label='Pharmacy latitude'
                        type='number'
                        fullWidth
                        variant='filled'
                        value={values.latitude}
                        error={touched.latitude && Boolean(errors.latitude)}
                        onChange={handleChange}
                        helperText={touched.latitude && errors.latitude}
                    />
                  </Grid>
                </Grid>
                <Grid padding={1} item xs={12}>
                  <FormControl variant={'filled'} fullWidth>
                    <InputLabel id={'group-label'}>Pharmacy Region</InputLabel>
                    <Select id='region' name='region' value={values.region} label='region' onChange={handleChange}>
                      {regions.map((region, index) => (
                        <MenuItem key={index} value={region.chineseName}>
                          {region.chineseName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid padding={1} item xs={12}>
                  <FormControl variant={'filled'} fullWidth>
                    <InputLabel id={'group-label'}>Pharmacy Network</InputLabel>
                    <Select id='pharmacyNet' name='pharmacyNet' value={values.pharmacyNet} label='pharmacyNet' onChange={handleChange}>
                      {nets.map((net, index) => (
                        <MenuItem key={index} value={net}>
                          {net}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid padding={1} item xs={12}>
                  {programNames.map((programName, index) => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            onClick={(e) => {
                              if (!programs.includes(programName)) {
                                setPrograms([...programs, programName])
                                return
                              }
                              setPrograms(programs.filter((program) => program !== programName))
                            }}
                            checked={programs.includes(programName)}
                          />
                        }
                        label={programName}
                      />
                    )
                  })}
                </Grid>
              </CardContent>
              <CardActions sx={{ backgroundColor: '#f1f1f1' }}>
                <Button type={'submit'} sx={{ margin: 1 }} startIcon={<Add />} variant={'contained'} color='primary'>
                  Create
                </Button>
                <Button
                  onClick={goBackToPharmciesPage}
                  sx={{ margin: 1 }}
                  startIcon={<CancelOutlined />}
                  variant={'contained'}
                  color='primary'
                >
                  Cancel
                </Button>
              </CardActions>
            </form>
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}

export default PharmacyCreationPageComponent
