import {Add, Check, Close, Download, Refresh} from '@mui/icons-material'
import {
    Button,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from '@mui/material'
import csvDownload from 'json-to-csv-export'
import {FC, useEffect, useMemo, useState, useCallback} from 'react'
import {useNavigate, useLocation} from 'react-router-dom'
import PharmaciesTableRowContainer from '../components/PharmaciesTableRowContainer'
import {Roles} from '../constants/roles'
import {GetPharmacyAction} from '../core/actions/pharmacyActionTypes'
import {PagedTracker, PageRequest, Pharmacy, PharmacyFilter, Region} from '../core/api/types'
import {RoleAccessProvider} from '../providers'
import {nets} from '../core/validations/consts-and-functions'
import {useFormik} from 'formik'
import dayjs from 'dayjs'

interface Props {
    getPharmacies: (pageRequest: PageRequest, filter?: PharmacyFilter) => GetPharmacyAction
    pharmacies: PagedTracker<Pharmacy>,
    regions: Region[],
    programNames: string[]
}

export const PharmacyPageComponent: FC<Props> = ({
                                                     programNames,
                                                     regions,
                                                     getPharmacies,
                                                     pharmacies: pagedPharmacies
                                                 }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const previousPage = location.state?.from
    const [filters, setFilters] = useState<PharmacyFilter>(previousPage === '/pharmacies/create' || previousPage === '/pharmacies/update' ? location.state?.filter : {} as PharmacyFilter);
    const [page, setPage] = useState(previousPage === '/pharmacies/create' || previousPage === '/pharmacies/update' ? pagedPharmacies.page : 0)
    const [size, setSize] = useState(5)
    const pharmacies = pagedPharmacies.data

    const tableHeaders = useMemo(
        () => [
            '',
            'Login ID',
            'Pharmacy ID',
            'Name',
            'Phone Number',
            'E-mail',
            'Network',
            'Longitude/Latitude',
            'Store Address',
            'HK City District',
            'Medicine',
            'Stamp Collection Programs',
            'Instant Discount Programs',
        ],
        [],
    )

    const filterFormik = useFormik({
        enableReinitialize: true,
        initialValues: {
            net: filters?.net === undefined ? null : filters?.net,
            region: filters?.region === undefined ? null : filters?.region,
            program: filters?.program === undefined ? null : filters?.program
        },
        onSubmit: (filters: PharmacyFilter) => {
            console.log("FILTERS", filters);
            setPage(0);
            setFilters(filters);
        },
    })

    const resetFilters = () => {
        setPage(0)
        setSize(5)
        setFilters({} as PharmacyFilter)
        filterFormik.resetForm({
            values: {
                net: null,
                region: null,
                program: null
            }
        })
    }

    const resetNavigateState = useCallback(() => {
        navigate(location.pathname, {})
    }, [navigate, location.pathname])

    useEffect(() => {
        getPharmacies({page, size}, filters);
    }, [getPharmacies, page, size])

    useEffect(() => {
        getPharmacies({page, size}, filters);
    }, [filters]);

    useEffect(() => {
        if (previousPage === '/pharmacies/create' || previousPage === '/pharmacies/update') resetNavigateState()
    }, [previousPage, resetNavigateState,])

    return (
        <RoleAccessProvider required={[Roles.VIEW_PHARMACIES]}>
            <>
                <Grid container justifyContent='flex-end' style={{marginBottom: 24}}>
                    <RoleAccessProvider required={[Roles.MANAGE_PHARMACIES]}>
                        <Button onClick={() => navigate(`/pharmacies/create`, {state: {filter: filters}})}
                                style={{marginRight: 12}} startIcon={<Add/>} variant={'contained'}>
                            Create
                        </Button>
                    </RoleAccessProvider>
                    <Button
                        onClick={() => csvDownload({
                            delimiter: ',', data: pharmacies.content.map((content) => {
                                return {
                                    "Login ID": content.identity.username,
                                    "Pharmacy ID": content.id,
                                    "Name": content.nameChinese,
                                    "Phone Number": content.phoneNumber,
                                    "E-Mail": content.email,
                                    "Network": content.pharmacyNet,
                                    "Longitude/Latitude": content.longitude + '/' + content.latitude,
                                    "Store Address": content.addressChinese,
                                    "Medicine": content.brands.join(", "),
                                    "Stamp Collection Programs": content.stampCollectionPrograms.map((program) => program.name).join(", "),
                                    "Instant Discount Programs": content.instantDiscountPrograms.map((program) => program.name).join(", "),
                                };
                            })
                        })}
                        style={{marginRight: 12}}
                        startIcon={<Download/>}
                        variant={'contained'}
                    >
                        Export
                    </Button>
                    <Button onClick={() => getPharmacies({page, size}, filters)} startIcon={<Refresh/>}
                            variant={'contained'}>
                        Refresh
                    </Button>
                </Grid>
                <Grid container justifyContent='flex-end' style={{marginBottom: 24}}>
                    <form onSubmit={filterFormik.handleSubmit} style={{display: "flex"}}>
                        <FormControl style={{marginLeft: 8}} variant={'filled'} fullWidth>
                            <InputLabel id={'group-label'}>Pharmacy Network</InputLabel>
                            <Select

                                style={{width: 200}}
                                labelId={'group-label'}
                                id='net'
                                name='net'
                                value={filterFormik.values.net}
                                label='Pharmacy network'

                                onChange={(val) => {
                                    filterFormik.setFieldValue('net', val.target.value)
                                }}
                            >
                                {nets.map((net, index) => (
                                    <MenuItem key={index} value={net}>
                                        {net}
                                    </MenuItem>
                                ))}
                            </Select>

                        </FormControl>
                        <FormControl style={{marginLeft: 8}} variant={'filled'} fullWidth>
                            <InputLabel id={'group-label'}>District</InputLabel>
                            <Select

                                style={{width: 200}}
                                labelId={'group-label'}
                                id='region'
                                name='region'
                                value={filterFormik.values.region}
                                label='District'

                                onChange={(val) => {
                                    filterFormik.setFieldValue('region', val.target.value)
                                }}
                            >
                                {regions.map((region, index) => (
                                    <MenuItem key={index} value={region.chineseName}>
                                        {region.chineseName}
                                    </MenuItem>
                                ))}
                            </Select>

                        </FormControl>
                        <FormControl style={{marginLeft: 8}} variant={'filled'} fullWidth>
                            <InputLabel id={'group-label'}>Program</InputLabel>
                            <Select

                                style={{width: 200}}
                                labelId={'group-label'}
                                id='program'
                                name='program'
                                value={filterFormik.values.program}
                                label='Program'

                                onChange={(val) => {
                                    filterFormik.setFieldValue('program', val.target.value)
                                }}
                            >
                                {programNames.map((program, index) => (
                                    <MenuItem key={index} value={program}>
                                        {program}
                                    </MenuItem>
                                ))}
                            </Select>

                        </FormControl>
                    </form>
                    <IconButton style={{paddingLeft: 20, paddingRight: 20, marginLeft: 8}}
                                onClick={filterFormik.submitForm}><Check/></IconButton>
                    <IconButton type='reset' style={{paddingLeft: 20, paddingRight: 20}} onClick={resetFilters}><Close/></IconButton>
                </Grid>
                <Paper>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {tableHeaders.map((i) => (
                                        <TableCell key={i} align={'center'}>
                                            {i}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {pharmacies.content.map((pharmacy, index) => (
                                    <PharmaciesTableRowContainer key={index} pharmacy={pharmacy}/>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[
                            {label: '5', value: 5},
                            {label: '10', value: 10},
                            {label: '15', value: 15},
                            {label: '20', value: 20},
                            {label: '50', value: 50},
                            {label: '100', value: 100},
                            {label: '500', value: 500},
                            {label: '1000', value: 1000},
                            {label: 'All', value: pharmacies.totalItems}
                        ]}
                        component='div'
                        count={pharmacies.totalItems}
                        rowsPerPage={size}
                        page={page}
                        onPageChange={(_, currentPage) => setPage(currentPage)}
                        onRowsPerPageChange={(e) => {
                            setSize(Number(e.target.value))
                            setPage(0)
                        }}
                    />
                </Paper>
            </>
        </RoleAccessProvider>
    )
}
