import { FC, Fragment } from "react";
import { Brand } from "../core/api/types";
import { Button, IconButton, TableCell, TableRow } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { DeactivateBrandAction } from "../core/actions/brandActionTypes";
import "../styles/brand-table.scss";
import { Roles } from "../constants/roles";
import { RoleAccessProvider } from "../providers";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
interface Props {
  brand: Brand;
  deactivateBrand: (id: string) => DeactivateBrandAction;
}

const BrandsTableRowComponent: FC<Props> = ({ brand, deactivateBrand }) => {
  const navigate = useNavigate();

  return (
    <Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        hover
        key={brand.id}
      >


        <TableCell style={{ width: "5%" }}>
          <RoleAccessProvider required={[Roles.MANAGE_BRANDS]}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => navigate(`/brands/${brand.id}`)}>
              <InfoOutlined />
            </IconButton>
          </RoleAccessProvider>
        </TableCell>

        <TableCell style={{ textAlign: "center" }}>
          <img className={"brand-table-logo"} alt={brand.name} src={`${process.env.REACT_APP_HOST_BASE_URL}/api/storage/image?id=${brand.logo}`}></img>
        </TableCell>
        <TableCell style={{ textAlign: "center" }}>
          <img className={"brand-table-logo"} alt={brand.name} src={`${process.env.REACT_APP_HOST_BASE_URL}/api/storage/image?id=${brand.image}`}></img>
        </TableCell>
        <TableCell style={{ textAlign: "center" }}>{brand.name}</TableCell>
        <TableCell style={{ textAlign: "center" }}>
          {dayjs(brand.createdDate).tz("Asia/Hong_Kong").tz('Asia/Hong_Kong').format('DD-MM-YYYY')}
        </TableCell>
        <TableCell style={{ textAlign: "center" }}>
          {brand.programs.map((program, index) => (
            <p>{program}</p>
          ))}
        </TableCell>

        <TableCell style={{ textAlign: "center" }}>
          {brand.active ? (
            <Button
              onClick={() => {
                deactivateBrand(brand.id);
                navigate(`/brands`);
              }}
            >
              Deactivate
            </Button>
          ) : (
            <Button
              onClick={() => {
                deactivateBrand(brand.id);
                navigate(`/brands`);
              }}
              color={"success"}
            >
              Activate
            </Button>
          )}
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

export default BrandsTableRowComponent;
