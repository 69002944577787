import { Check, Close, Download, Key, Save, Settings } from '@mui/icons-material'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from '@mui/material'
import { useFormik } from 'formik'
import { FC, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import PharmacyDiscountTableRowContainer from '../components/PharmacyDiscountTableRowContainer'
import PharmacyPendingTableRowContainer from '../components/PharmacyPendingTableRowContainer'
import PharmacyPickedUpTableRowContainer from '../components/PharmacyPickedUpTableRowContainer'
import { GetDiscountByPharmacyAction, GetHaSfiByPharmacyAction } from '../core/actions/discountActionTypes'
import { GetStampPendingAction, GetStampPickedUpAction } from '../core/actions/stampActionTypes'
import { UpdateIdentityAction } from '../core/actions/identityActionTypes'
import { GetPharmacyDataAction, UpdatePharmacyAction } from '../core/actions/pharmacyActionTypes'
import { GetProgramNamesAction } from '../core/actions/programActionTypes'
import {
  Discount,
  IdentityUpdateData,
  Paged,
  PageRequest,
  Pharmacy,
  PharmacyUpdate,
  Region,
  Stamp,
  TransactionFilter,
} from '../core/api/types'
import { createPharamacyValidationSchema, updateIdentityValidationSchema } from '../core/validations'
import { nets } from '../core/validations/consts-and-functions'
import { RoleAccessProvider } from '../providers'
import { Roles } from '../constants/roles'
import PharmacyHaSfiTableRowContainer from '../components/PharmacyHaSfiTableRowContainer'
import { LocalizationProvider, DatePicker, DateTimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { useLocalization } from '../hooks/useLocalization'
import csvDownload from 'json-to-csv-export'
import { isRole } from '../helpers/roleChecker'
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
interface Props {
  pharmacy: Pharmacy | null
  getPharmacyData: (id: string) => GetPharmacyDataAction
  getDiscountByPharmacy: (id: string, pageRequest: PageRequest, filter?: TransactionFilter) => GetDiscountByPharmacyAction
  getHaSfiByPharmacy: (id: string, pageRequest: PageRequest, filter?: TransactionFilter) => GetHaSfiByPharmacyAction
  getPendingByPharmacy: (id: string, pageRequest: PageRequest, filter?: TransactionFilter) => GetStampPendingAction
  getPickedUpByPharmacy: (id: string, pageRequest: PageRequest, filter?: TransactionFilter) => GetStampPickedUpAction
  discounts: Paged<Discount>
  hasfis: Paged<Discount>
  stampsPending: Paged<Stamp>
  stampsPickedUp: Paged<Stamp>
  getProgramNames: () => GetProgramNamesAction
  regions: Region[]
  programNames: string[],
  programNamesWithBrand: string[],
  brandNames: string[]
  updateIdentity: (accessControlDetails: IdentityUpdateData) => UpdateIdentityAction
  updatePharmacy: (updatePharmacyData: PharmacyUpdate) => UpdatePharmacyAction,
  roles: string[]
}

function generatePassword(length: number) {
  let result = ''
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

const PharmacyDataPageComponent: FC<Props> = ({
  pharmacy,
  getPharmacyData,
  updateIdentity,
  updatePharmacy,
  getProgramNames,
  getDiscountByPharmacy,
  getHaSfiByPharmacy,
  getPendingByPharmacy,
  getPickedUpByPharmacy,
  discounts,
  hasfis,
  stampsPending,
  stampsPickedUp,
  programNames,
  brandNames,
  regions,
  programNamesWithBrand,
  roles
}) => {
  const { pharmacyId } = useParams()
  const [page1, setPage1] = useState(0)
  const [size1, setSize1] = useState(10)
  const [page2, setPage2] = useState(0)
  const [size2, setSize2] = useState(10)
  const [page3, setPage3] = useState(0)
  const [size3, setSize3] = useState(10)
  const [page4, setPage4] = useState(0)
  const [size4, setSize4] = useState(10)
  const [filters, setFilters] = useState<TransactionFilter>()

  const pendingHeaders = useMemo(() => ['Transaction ID', 'Patient ID', 'Brand', 'Dosage', 'Number of Boxes'], [])

  const pickedUpHeaders = useMemo(() => ['Transaction ID', 'Patient ID', 'Brand', 'Dosage', 'Number of Boxes', 'Picked Up Date'], [])

  const instantHeaders = useMemo(
    () => ['Purchase ID', 'Patient ID', 'Brand', 'Program', 'Number of Boxes', 'Discount Amount', 'Purchase Date'],
    [],
  )

  const haSfiHeaders = useMemo(() => ['Purchase ID', 'Patient ID', 'Brand', 'Number of Boxes', 'Purchase Date'], [])

  const [programs, setPrograms] = useState<string[]>([])
  const [tab, setTab] = useState(0)

  const filterFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      dateFrom: null,
      dateTo: null,
      brand: null,
      program: null,
    },
    onSubmit: (filters: TransactionFilter) => {
      console.log(filters)

      setFilters(filters)
    },
  })

  const { values, errors, touched, handleSubmit, handleChange, setFieldValue } = useFormik({
    initialValues: {
      nameChinese: '',
      pharmacyNet: '',
      addressChinese: '',
      region: '',
      phoneNumber: '',
      email: '',
      isPasswordOneTime: false,
      isActive: true,
      dateRegistered: new Date(),
      group: 'Zuellig',
      username: '',
      longitude: null,
      latitude:  null,
    },
    validationSchema: createPharamacyValidationSchema,
    onSubmit: (pharmacyData: any) => {
      if (!pharmacyId) return

      const pharmacyUpdate: PharmacyUpdate = {
        nameChinese: pharmacyData.nameChinese,
        pharmacyNet: pharmacyData.pharmacyNet,
        addressChinese: pharmacyData.addressChinese,
        region: pharmacyData.region,
        phoneNumber: pharmacyData.phoneNumber,
        email: pharmacyData.email,
        programs,
        id: pharmacyId,
        latitude: pharmacyData.latitude,
        longitude: pharmacyData.longitude,
      }
      updatePharmacy(pharmacyUpdate)
    },
  })

  const resetPageAndRowsPerPage = () => {
    setPage1(0)
    setSize1(10)
    setPage2(0)
    setSize2(10)
    setPage3(0)
    setSize3(10)
    setPage4(0)
    setSize4(10)
  }

  const resetFilters = () => {
    resetPageAndRowsPerPage()
    setFilters({} as TransactionFilter)
    filterFormik.resetForm({
      values: {
        dateFrom: null,
        dateTo: null,
        brand: null,
        program: null,
      }
    })
  }
  useEffect(() => {
    getProgramNames()
    if (pharmacyId) {
      getPharmacyData(pharmacyId)
    }
  }, [])

  useEffect(() => {
    if (pharmacyId) {
      getPharmacyData(pharmacyId)
    }
  }, [pharmacyId, getPharmacyData])

  useEffect(() => {
    if (pharmacyId) {
      console.log('FILTERS', filters)
      getDiscountByPharmacy(pharmacyId, { page: page3, size: size3 }, filters)
    }
  }, [pharmacyId, getDiscountByPharmacy, page3, size3])

  useEffect(() => {
    if (pharmacyId) {
      getHaSfiByPharmacy(pharmacyId, { page: page4, size: size4 }, filters)
    }
  }, [pharmacyId, getHaSfiByPharmacy, page4, size4])

  useEffect(() => {
    if (pharmacyId) {
      getPendingByPharmacy(pharmacyId, { page: page1, size: size1 }, filters)
    }
  }, [pharmacyId, getPendingByPharmacy, page1, size1])

  useEffect(() => {
    if (pharmacyId) {
      getPickedUpByPharmacy(pharmacyId, { page: page2, size: size2 }, filters)
    }
  }, [pharmacyId, getPickedUpByPharmacy, page2, size2])

  useEffect(() => {
    if (pharmacyId) {
      console.log('FILTERS 2', filters)

      getDiscountByPharmacy(pharmacyId, { page: page3, size: size3 }, filters)
      getHaSfiByPharmacy(pharmacyId, { page: page4, size: size4 }, filters)
      getPendingByPharmacy(pharmacyId, { page: page1, size: size1 }, filters)
      getPickedUpByPharmacy(pharmacyId, { page: page2, size: size2 }, filters)
    }
  }, [filters])

  useEffect(() => {
    if (pharmacy) {
      setFieldValue('nameChinese', pharmacy.nameChinese)
      setFieldValue('pharmacyNet', pharmacy.pharmacyNet)
      setFieldValue('addressChinese', pharmacy.addressChinese)
      setFieldValue('longitude', pharmacy.longitude)
      setFieldValue('latitude', pharmacy.latitude)
      setFieldValue('region', pharmacy.region)
      setFieldValue('phoneNumber', pharmacy.phoneNumber)
      setFieldValue('email', pharmacy.email)
    }
    setPrograms(pharmacy?.instantDiscountPrograms.map((p) => p.name).concat(pharmacy?.stampCollectionPrograms.map((p) => p.name)) || [])
  }, [pharmacy])

  const updateAccessControlFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: pharmacy?.identity.id,
      username: pharmacy?.identity.username,
      group: pharmacy?.identity.group,
      password: '',
      isPasswordOneTime: pharmacy?.identity.isPasswordOneTime,
      isActive: pharmacy?.identity.isActive,
    },
    validationSchema: updateIdentityValidationSchema,
    onSubmit: (accessControlData: IdentityUpdateData) => {
      updateIdentity(accessControlData)

      console.log(pharmacy)
    },
  })

  if (!pharmacy) return <div></div>

  return (
    <RoleAccessProvider required={[Roles.VIEW_PHARMACIES]}>
      <div>
        <Grid container justifyContent='flex-end' style={{ marginBottom: 24 }}>

          <Button
            onClick={
              () => {
                switch (tab) {
                  case 0:
                    csvDownload({
                      filename: "stamps-pending", delimiter: ',', data: stampsPending.content.map((enrollment => {
                        return {
                          "Brand Name": enrollment.brandName,
                          "Program Name": enrollment.enrollment.program.name,
                          "Dosage": enrollment.dosage,
                          "Number of Boxes": enrollment.numberOfBoxes,
                          "Patient Number ID": enrollment.patientNumberId,
                          "Pharmacy ID": enrollment.pharmacyId,
                          "Pharmacy Name": pharmacy.nameChinese,
                          "Pharmacy Address": pharmacy.addressChinese
                        }
                      }))
                    });
                    csvDownload({
                      filename: "stamps-picked-up", delimiter: ',', data: stampsPickedUp.content.map((enrollment => {
                        return {
                          "Brand Name": enrollment.brandName,
                          "Program Name": enrollment.enrollment.program.name,
                          "Dosage": enrollment.dosage,
                          "Number of Boxes": enrollment.numberOfBoxes,
                          "Patient Number ID": enrollment.patientNumberId,
                          "Pharmacy ID": enrollment.pharmacyId,
                          "Pharmacy Name": pharmacy.nameChinese,
                          "Pharmacy Address": pharmacy.addressChinese,
                          "Picked Up Date": enrollment.pickUpDate,
                          "Transaction ID": enrollment.transactionId
                        }
                      }))
                    });
                    break;
                    case 1:
                csvDownload({
                  filename: "instant-discounts", delimiter: ',', data: discounts.content.map((discount) => {
                    return {
                      "Purchase Date": discount.purchaseDate,
                      "Purchase ID": discount.id,
                      "Patient ID": discount.patientId,
                      ...isRole('fingerbox') && {
                        "Patient Address": discount.patient.address,
                        "Patient Email Address": discount.patient.email,
                        "Patient Phone Number": discount.phoneNumber,
                        "Patient Name": discount.patientName,
                      },
                      "Brand": discount.brandName,
                      "Program": discount.programName,
                      "Number of Boxes": discount.numberOfBoxes,
                      "Discount Amount": discount.discountAmount,
                      "Pharmacy ID": discount.pharmacyId,
                      "Pharmacy Name": discount.pharmacyName,
                      "Pharmacy Address": pharmacy.addressChinese,
                      "Pharmacy Net": discount.pharmacyNet,
                      "HK City District": discount.region,
                      "Zuellig ID": discount.zuelligId,
                    };
                  })
                });
                break;
                case 2:
                csvDownload({
                  filename: "ha-sfi", delimiter: ',', data: hasfis.content.filter(t => t.discountAmount === 0).map((discount) => {
                    return {
                      "Purchase Date": discount.purchaseDate,
                      "Purchase ID": discount.id,
                      "Patient ID": discount.patientId,
                      ...isRole('fingerbox') && {
                        "Patient Phone Number": discount.phoneNumber,
                        "Patient Name": discount.patientName
                      },
                      "Brand": discount.brandName,
                      "Program": discount.programName,
                      "Number of Boxes": discount.numberOfBoxes,
                      "Discount Amount": discount.discountAmount,
                      "Pharmacy ID": discount.pharmacyId,
                      "Pharmacy Name": discount.pharmacyName,
                      "Pharmacy Net": discount.pharmacyNet,
                      "HK City District": discount.region,
                      "Zuellig ID": discount.zuelligId,
                    };
                  })
                });
              break;
              }
              }}
            style={{ marginRight: 12 }}
            startIcon={<Download />}
            variant={'contained'}
          >
            Export
          </Button>
          <Button onClick={() => window.location.reload()} variant={'contained'}>
            Refresh
          </Button>
        </Grid>
        <Grid container xs={12}>
          <Grid item xs={3}>
            <Card>
              <CardHeader title={<b>{pharmacy.nameChinese}</b>} subheader={pharmacy.addressChinese} />
              <TableCell style={{ width: '15%', textAlign: 'center' }}>
                <RoleAccessProvider required={[Roles.MANAGE_PHARMACIES]}>
                  <Button
                    onClick={() => {
                      updateIdentity({
                        id: pharmacy.identity.id,
                        isActive: pharmacy.identity.isActive ? false : !pharmacy.identity.isActive && true,
                      })
                      setTimeout(() => {
                        window.location.reload();
                      }, 0);
                    }}
                    color={pharmacy.identity.isActive ? 'primary' : 'success'}
                  >
                    {pharmacy.identity.isActive ? 'Deactivate' : !pharmacy.identity.isActive && 'Activate'}
                  </Button>
                </RoleAccessProvider>
              </TableCell>
              <CardContent>
                <Typography>
                  <b>Pharmacy ID</b> - {pharmacy.id}
                </Typography>
                <br/>
                <Typography>
                  <b>Zuellig ID</b> - {pharmacy.identity.username}
                </Typography>
                <br/>
                <Typography>
                  <b>Region</b> - {pharmacy.region}
                </Typography>
                <br/>
                <Typography>
                  <b>longitude/latitude</b> - {pharmacy.longitude}/{pharmacy.latitude}
                </Typography>
                <br/>
                <Typography>
                  <b>E-Mail</b> - {pharmacy.email ?? 'Not specified'}
                </Typography>
                <br/>
                <Typography>
                  <b>Phone Number</b> - {pharmacy.phoneNumber ? pharmacy.phoneNumber : 'Not specified'}
                </Typography>
                <img
                    style={{width: '100%', marginTop: 24}}
                    src={`https://api.qrserver.com/v1/create-qr-code/?size=500x500&data=${pharmacy.id}`}
                />
              </CardContent>
            </Card>
            <Card style={{ marginTop: 12 }}>
              <CardHeader title={<b>Medicines</b>} />
              <CardContent>
                {pharmacy.brands.map((brand, index) => (
                  <Chip label={brand} />
                ))}
              </CardContent>
            </Card>
            <Card style={{ marginTop: 12 }}>
              <CardHeader title={<b>Stamp Collection Programs</b>} />
              <CardContent>
                {pharmacy.stampCollectionPrograms.map((program, index) => (
                  <Chip label={program.brand + " | " + program.name} />
                ))}
              </CardContent>
            </Card>
            <Card style={{ marginTop: 12 }}>
              <CardHeader title={<b>Instant Discount</b>} />
              <CardContent>
                {pharmacy.instantDiscountPrograms.map((program, index) => (
                  <Chip label={program.brand + " | " + program.name} />
                ))}
              </CardContent>
            </Card>
            <RoleAccessProvider required={[Roles.MANAGE_PHARMACIES]}>
              <Grid style={{ marginTop: 12 }} item xs={12}>
                <form onSubmit={updateAccessControlFormik.handleSubmit}>
                  <Card variant={'outlined'} sx={{ flex: 1 }}>
                    <CardContent>
                      <Box mb={1} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Key />
                        <Typography ml={2} variant={'h5'}>
                          <b>Access Control</b>
                        </Typography>
                      </Box>
                      <Typography variant={'subtitle2'}>Update credentials and roles. Leaving password empty won't change it.</Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            id='username'
                            label='Username'
                            margin='normal'
                            type='text'
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            variant='filled'
                            value={updateAccessControlFormik.values.username}
                            error={updateAccessControlFormik.touched.username && Boolean(updateAccessControlFormik.errors.username)}
                            onChange={updateAccessControlFormik.handleChange}
                            helperText={updateAccessControlFormik.touched.username && updateAccessControlFormik.errors.username}
                          />
                        </Grid>
                      </Grid>
                      <TextField
                        id='password'
                        label='New Password'
                        margin='normal'
                        type='text'
                        fullWidth
                        variant='filled'
                        value={updateAccessControlFormik.values.password}
                        error={updateAccessControlFormik.touched.password && Boolean(updateAccessControlFormik.errors.password)}
                        onChange={updateAccessControlFormik.handleChange}
                        helperText={updateAccessControlFormik.touched.password && updateAccessControlFormik.errors.password}
                      />
                    </CardContent>
                    <CardActions sx={{ backgroundColor: '#f1f1f1' }}>
                      <Button type={'submit'} sx={{ margin: 1 }} startIcon={<Save />} variant={'contained'} color='primary'>
                        Save
                      </Button>
                      <Button
                        onClick={() => {
                          updateAccessControlFormik.setFieldValue('password', generatePassword(8))
                        }}
                        sx={{ margin: 1 }}
                        startIcon={<Settings />}
                        variant={'contained'}
                        color='primary'
                      >
                        Generate password
                      </Button>
                    </CardActions>
                  </Card>
                </form>
              </Grid>
            </RoleAccessProvider>
            <RoleAccessProvider required={[Roles.MANAGE_PHARMACIES]}>
              <Grid item xs={12}>
                <Card style={{ marginTop: 12 }} sx={{ flex: 1 }}>
                  <form onSubmit={handleSubmit}>
                    <CardContent>
                      <Grid container>
                        <Grid padding={1} item xs={6}>
                          <TextField
                            id='nameChinese'
                            label='Pharmacy Name'
                            type='text'
                            fullWidth
                            variant='filled'
                            value={values.nameChinese}
                            error={touched.nameChinese && Boolean(errors.nameChinese)}
                            onChange={handleChange}
                            helperText={touched.nameChinese && errors.nameChinese}
                          />
                        </Grid>
                        <Grid padding={1} item xs={6}>
                          <TextField
                            id='addressChinese'
                            label='Pharmacy Address'
                            type='text'
                            fullWidth
                            variant='filled'
                            value={values.addressChinese}
                            error={touched.addressChinese && Boolean(errors.addressChinese)}
                            onChange={handleChange}
                            helperText={touched.addressChinese && errors.addressChinese}
                          />
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid padding={1} item xs={6}>
                          <TextField
                            id='email'
                            label='Pharmacy E-Mail address'
                            type='text'
                            fullWidth
                            variant='filled'
                            value={values.email}
                            error={touched.email && Boolean(errors.email)}
                            onChange={handleChange}
                            helperText={touched.email && errors.email}
                          />
                        </Grid>
                        <Grid padding={1} item xs={6}>
                          <TextField
                            id='phoneNumber'
                            label='Pharmacy Phone Number'
                            type='text'
                            fullWidth
                            variant='filled'
                            value={values.phoneNumber}
                            error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                            onChange={handleChange}
                            helperText={touched.phoneNumber && errors.phoneNumber}
                          />
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid padding={1} item xs={6}>
                          <TextField
                              id='longitude'
                              label='Pharmacy longitude'
                              type='text'
                              fullWidth
                              variant='filled'
                              value={values.longitude}
                              error={touched.longitude && Boolean(errors.longitude)}
                              onChange={handleChange}
                              helperText={touched.longitude && errors.longitude}
                          />
                        </Grid>
                        <Grid padding={1} item xs={6}>
                          <TextField
                              id='latitude'
                              label='Pharmacy latitude'
                              type='number'
                              fullWidth
                              variant='filled'
                              value={values.latitude}
                              error={touched.latitude && Boolean(errors.latitude)}
                              onChange={handleChange}
                              helperText={touched.latitude && errors.latitude}
                          />
                        </Grid>
                      </Grid>
                      <Grid padding={1} item xs={12}>
                        <FormControl variant={'filled'} fullWidth>
                          <InputLabel id={'group-label'}>Pharmacy Region</InputLabel>
                          <Select id='region' name='region' value={values.region} label='region' onChange={handleChange}>
                            {regions.map((region, index) => (
                              <MenuItem key={index} value={region.chineseName}>
                                {region.chineseName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid padding={1} item xs={12}>
                        <FormControl variant={'filled'} fullWidth>
                          <InputLabel id={'group-label'}>Pharmacy Network</InputLabel>
                          <Select
                            id='pharmacyNet'
                            name='pharmacyNet'
                            value={values.pharmacyNet}
                            label='pharmacyNet'
                            onChange={handleChange}
                          >
                            {nets.map((net, index) => (
                              <MenuItem key={index} value={net}>
                                {net.toUpperCase()}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid padding={1} item xs={12}>
                        {programNamesWithBrand.map((programNameWithBrand, index) => {
                          let programName = programNameWithBrand.split(" | ").pop()!;
                          return (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onClick={(e) => {
                                    if (!programs.includes(programName)) {
                                      setPrograms([...programs, programName])
                                      return
                                    }
                                    setPrograms(programs.filter((program) => program !== programName))
                                  }}
                                  checked={programs.includes(programName)}
                                />
                              }
                              label={programNameWithBrand}
                            />
                          )
                        })}
                      </Grid>
                    </CardContent>
                    <CardActions sx={{ backgroundColor: '#f1f1f1' }}>
                      <Button type={'submit'} sx={{ margin: 1 }} startIcon={<Save />} variant={'contained'} color='primary'>
                        Save
                      </Button>
                    </CardActions>
                  </form>
                </Card>
              </Grid>
            </RoleAccessProvider>
          </Grid>

          <Grid item xs={9}>
            <Tabs
              value={tab}
              style={{ marginLeft: 18, marginBottom: 12 }}
              onChange={(event: React.SyntheticEvent, newValue: number) => {
                setTab(newValue)
              }}
              aria-label='basic tabs example'
            >
              <Tab label='Stamp Collection' {...a11yProps(0)} />
              <Tab label='Instant Discount' {...a11yProps(1)} />
              <Tab label='HA SFI' {...a11yProps(2)} />
            </Tabs>
            <Grid container style={{ marginBottom: 24 }}>
              <form onSubmit={filterFormik.handleSubmit} style={{ display: 'flex', marginTop: 8, marginLeft: 42 }}>
                <FormControl variant={'filled'} fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      label='Date From'
                      value={filterFormik.values.dateFrom}
                      inputFormat='YYYY-MM-DD'
                      onChange={(val) => {
                        filterFormik.setFieldValue('dateFrom', val == null ? null : val)
                      }}
                      renderInput={(params) => <TextField {...params} variant={'filled'} />}
                    />
                  </LocalizationProvider>
                </FormControl>
                <FormControl style={{ marginLeft: 8 }} variant={'filled'} fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      label='Date To'
                      value={filterFormik.values.dateTo}
                      inputFormat='YYYY-MM-DD'
                      onChange={(val) => {
                        filterFormik.setFieldValue('dateTo', val == null ? null : val)
                      }}
                      renderInput={(params) => <TextField {...params} variant={'filled'} />}
                    />
                  </LocalizationProvider>
                </FormControl>
                <FormControl style={{ marginLeft: 8 }} variant={'filled'} fullWidth>
                  <InputLabel id={'group-label'}>Brand</InputLabel>
                  <Select
                    labelId={'group-label'}
                    id='brand'
                    name='brand'
                    value={filterFormik.values.brand}
                    label='Brand'
                    onChange={(val) => {
                      filterFormik.setFieldValue('brand', val.target.value)
                    }}
                  >
                    {brandNames.map((brand, index) => (
                      <MenuItem key={index} value={brand}>
                        {brand}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl variant={'filled'} style={{ marginLeft: 8 }} fullWidth>
                  <InputLabel id={'group-label'}>Program</InputLabel>
                  <Select
                    labelId={'group-label'}
                    id='program'
                    name='program'
                    value={filterFormik.values.program}
                    label='Program'
                    onChange={(val) => {
                      filterFormik.setFieldValue('program', val.target.value)
                    }}
                  >
                    {programNames.map((program, index) => (
                      <MenuItem key={index} value={program}>
                        {program}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </form>
              <IconButton style={{ paddingLeft: 20, paddingRight: 20, marginLeft: 8 }} onClick={filterFormik.submitForm}>
                <Check />
              </IconButton>
              <IconButton
                type='reset'
                style={{ paddingLeft: 20, paddingRight: 20 }}
                onClick={resetFilters}
              >
                <Close />
              </IconButton>
            </Grid>
            <TabPanel value={tab} index={0}>
              <Card style={{ marginLeft: 18, marginTop: 18 }}>
                <CardHeader title={<b>Pending order</b>} />
                <CardContent>
                  <Paper>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            {pendingHeaders.map((i) => (
                              <TableCell key={i} align={'center'}>
                                {i}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {stampsPending.content.map((stamp, index) => (
                            <PharmacyPendingTableRowContainer key={index} stamp={stamp} />
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[
                        { label: '5', value: 5 },
                        { label: '10', value: 10 },
                        { label: '15', value: 15 },
                        { label: '20', value: 20 },
                        { label: 'All', value: stampsPending.totalItems }
                      ].filter((row) => +row.value > 0)}
                      component='div'
                      count={stampsPending.totalItems}
                      rowsPerPage={size1}
                      page={page1}
                      onPageChange={(_, currentPage) => setPage1(currentPage)}
                      onRowsPerPageChange={(e) => {
                        setSize1(Number(e.target.value))
                        setPage1(0)
                      }}
                    />
                  </Paper>
                </CardContent>
              </Card>

              <Card style={{ marginLeft: 18, marginTop: 18 }}>
                <CardHeader title={<b>Picked up</b>} />
                <CardContent>
                  <Paper>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            {pickedUpHeaders.map((i) => (
                              <TableCell key={i} align={'center'}>
                                {i}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {stampsPickedUp.content.map((stamp, index) => (
                            <PharmacyPickedUpTableRowContainer key={index} stamp={stamp} />
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[
                        { label: '5', value: 5 },
                        { label: '10', value: 10 },
                        { label: '15', value: 15 },
                        { label: '20', value: 20 },
                        { label: 'All', value: stampsPickedUp.totalItems }
                      ].filter((row) => +row.value > 0)}
                      component='div'
                      count={stampsPickedUp.totalItems}
                      rowsPerPage={size2}
                      page={page2}
                      onPageChange={(_, currentPage) => setPage2(currentPage)}
                      onRowsPerPageChange={(e) => {
                        setSize2(Number(e.target.value))
                        setPage2(0)
                      }}
                    />
                  </Paper>
                </CardContent>
              </Card>
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <Card style={{ marginLeft: 18, marginTop: 18 }}>
                <CardHeader title={<b>Instant Discounts</b>} />
                <CardContent>
                  <Paper>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            {instantHeaders.map((i) => (
                              <TableCell key={i} align={'center'}>
                                {i}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {discounts.content.map((discount, index) => (
                            <PharmacyDiscountTableRowContainer key={index} discount={discount} />
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[
                        { label: '5', value: 5 },
                        { label: '10', value: 10 },
                        { label: '15', value: 15 },
                        { label: '20', value: 20 },
                        { label: 'All', value: discounts.totalItems }
                      ].filter((row) => +row.value > 0)}
                      component='div'
                      count={discounts.totalItems}
                      rowsPerPage={size3}
                      page={page3}
                      onPageChange={(_, currentPage) => setPage3(currentPage)}
                      onRowsPerPageChange={(e) => {
                        setSize3(Number(e.target.value))
                        setPage3(0)
                      }}
                    />
                  </Paper>
                </CardContent>
              </Card>
            </TabPanel>
            <TabPanel value={tab} index={2}>
              <Card style={{ marginLeft: 18, marginTop: 18 }}>
                <CardHeader title={<b>HA SFI</b>} />
                <CardContent>
                  <Paper>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            {haSfiHeaders.map((i) => (
                              <TableCell key={i} align={'center'}>
                                {i}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {hasfis.content.map((hasfi, index) => (
                            <PharmacyHaSfiTableRowContainer key={index} discount={hasfi} />
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[
                        { label: '5', value: 5 },
                        { label: '10', value: 10 },
                        { label: '15', value: 15 },
                        { label: '20', value: 20 },
                        { label: 'All', value: hasfis.totalItems }
                      ].filter((row) => +row.value > 0)}
                      component='div'
                      count={hasfis.totalItems}
                      rowsPerPage={size4}
                      page={page4}
                      onPageChange={(_, currentPage) => setPage4(currentPage)}
                      onRowsPerPageChange={(e) => {
                        setSize4(Number(e.target.value))
                        setPage4(0)
                      }}
                    />
                  </Paper>
                </CardContent>
              </Card>
            </TabPanel>
          </Grid>
        </Grid>
      </div>
    </RoleAccessProvider>
  )
}

export default PharmacyDataPageComponent
