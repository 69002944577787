import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { GetBrandAction } from '../core/actions/brandActionTypes'
import { PageRequest, Brand, PagedTracker } from '../core/api/types'
import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import { Add, Download, Refresh } from '@mui/icons-material'
import csvDownload from 'json-to-csv-export'
import BrandsTableRowContainer from '../components/BrandsTableRowContainer'
import { useNavigate, useLocation } from 'react-router-dom'
import { RoleAccessProvider } from '../providers'
import { Roles } from '../constants/roles'
import dayjs from 'dayjs'

interface Props {
  getBrands: (pageRequest: PageRequest) => GetBrandAction
  brands: PagedTracker<Brand>
}

export const BrandPageComponent: FC<Props> = ({ getBrands, brands: pagedBrands }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const previousPage = location.state?.from
  const [page, setPage] = useState(previousPage === '/brands/create' || previousPage === '/brands/update' ? pagedBrands.page : 0)
  const [size, setSize] = useState(5)
  const brands = pagedBrands.data
  const tableHeaders = useMemo(() => ['', '', '', 'Brand Name', 'Created Date', 'Active Programs', 'Action'], [])

  const refreshPage = useCallback(() => {
    getBrands({ page, size })
  }, [getBrands, page, size])

  useEffect(() => {
    refreshPage()
  }, [refreshPage])

  return (
    <RoleAccessProvider required={[Roles.VIEW_BRANDS]}>
      <div>
        <Grid container justifyContent='flex-end' style={{ marginBottom: 24 }}>
          <RoleAccessProvider required={[Roles.MANAGE_BRANDS]}>
            <Button onClick={() => navigate(`/brands/create`)} style={{ marginRight: 12 }} startIcon={<Add />} variant={'contained'}>
              Create
            </Button>
          </RoleAccessProvider>
          <Button
            onClick={() => csvDownload({
              delimiter: ',', data: brands.content.map(content => {
                return {
                  "Brand Name": content.name,
                  "Created Date": dayjs(content.createdDate).tz('Asia/Hong_Kong').format('DD-MM-YYYY'),
                  "Active Programs": content.programs.join(", "),
                  "Active": content.active
                }
              })
            })}
            style={{ marginRight: 12 }}
            startIcon={<Download />}
            variant={'contained'}
          >
            Export
          </Button>
          <Button onClick={() => getBrands({ page, size })} startIcon={<Refresh />} variant={'contained'}>
            Refresh
          </Button>
        </Grid>
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {tableHeaders.map((i, index) => (
                    <TableCell key={i} align={'center'}>
                      {i}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {brands.content.map((brand, index) => (
                  <BrandsTableRowContainer brand={brand} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[
              { label: '5', value: 5 },
              { label: '10', value: 10 },
              { label: '15', value: 15 },
              { label: '20', value: 20 },
              { label: '50', value: 50 },
              { label: '100', value: 100 },
              { label: '500', value: 500 },
              { label: '1000', value: 1000 },
              { label: 'All', value: brands.totalItems }
            ]}
            component='div'
            count={brands.totalItems}
            rowsPerPage={size}
            page={page}
            onPageChange={(_, currentPage) => setPage(currentPage)}
            onRowsPerPageChange={(e) => {
              setSize(Number(e.target.value))
              setPage(0)
            }}
          />
        </Paper>
      </div>
    </RoleAccessProvider>
  )
}
